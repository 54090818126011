.blogs-div {
    width: 65%;

    margin-block: 20px;
    border-bottom: 4px solid #485265;
    padding-bottom: 40px;

    color: #e8f3fe;
    font-family: "Raleway", sans-serif;
}

.blog-title {
    text-align: left;
    font-size: 26px;
    font-weight: 700;

    color: #32cd9e;
}

.blog-description {
    margin-top: 10px;
    text-align: justify;
}

.blog-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-top: 15px;
    font-size: 12px;
    font-family: "Salsa", sans-serif;
}

.blog-readme-button-div {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.blog-readme-button {
    border: none;
    background-color: transparent;

    font-size: 14px;
    font-weight: 700;
    color: #2bbb99;
    cursor: pointer;

    font-family: "Salsa", sans-serif;
}

.blogs-keywords {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-family: "Salsa", sans-serif;
}

.blog-keyword {
    padding-inline: 12px;
    padding-block: 6px;

    background-color: #2bbb99;
    color: #1c1c1c;
    font-weight: 500;
    margin-right: 10px;
    border-radius: 100px;
    font-size: 12px;
}

.blogs-keyword-title {
    text-align: left;
    margin-bottom: 5px;

    font-weight: 700;
}