@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Palanquin:wght@100;200;300;400;500;600;700&family=Quattrocento:wght@400;700&family=Salsa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.lp-app-bar {
    width: 100%;
    height: 80px;

    background-color: #273246;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 0px 10px #e8f3fe1a;
    margin-bottom: 50px;
}

.lp-app-bar-content {
    width: 65%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lp-app-bar-title {
    font-family: "Abril Fatface", serif;

    font-size: 22px;
    color: #e8f3fe;
}

.lp-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.lp-link-button {
    cursor: pointer;
    border: none;

    background-color: transparent;

    /* font-family: "Quattrocento", serif; */
    font-family: "Roboto", sans-serif;
    padding-inline: 20px;
    padding-block: 10px;
    border-radius: 100px;
    margin-inline: 10px;
    font-size: 16px;
    /* font-weight: 600; */

    color: #e8f3fe;
}

.lp-link-button:hover {
    /* font-family: "Quattrocento", serif; */
    background-color: #e8f3fe;;
    color: #364156;
    font-weight: 600;
    box-shadow: 0px 0px 10px #e8f3fe66;
}

.blog-div {
    width: 70%;
    margin-top: 20px;
}